@import '../../styles/breakpoints.scss';

.container {
  cursor: pointer;
  height: 14px;
  width: 20px;
  margin-left: 8px;

  .menuButton {
    width: 100%;
    cursor: pointer;
    transition: opacity 0.25s ease;
    position: relative;

    &.active {
      .topLine {
        transform: translateY(6px) translateX(0) rotate(45deg);
      }
      .centerLine {
        width: 100%;
        left: 0px;
        opacity: 0;
      }

      .bottomLine {
        width: 100%;
        left: 0px;
        transform: translateY(-6px) translateX(0) rotate(-45deg);
      }
    }

    span {
      background: var(--malm-malm-0);
      border: none;
      height: 2px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.35s ease;
      cursor: pointer;

      &:nth-of-type(2) {
        top: 6px;
      }

      &:nth-of-type(3) {
        top: 12px;
      }
    }
  }
  @include largerThan(large) {
    display: none;
  }
}
