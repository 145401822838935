@import '../../styles/breakpoints.scss';

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 0;
  font-size: 24px;
  z-index: 3;
  cursor: pointer;
  padding: 0;
}

.imageContainer {
  height: 100vh;
  display: flex !important;
  align-items: center;
}

.imageContainer:focus {
  outline: none;
}

.image {
  max-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  place-content: center;
  background: white;
  position: absolute;
  right: 12px;
  left: 12px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 91vw;
  bottom: initial;
  border-radius: 12px;
  padding: 10%;
  padding-top: 70px; // making sure the X to close the modal gets enough space
  padding-bottom: 70px;
  top: 50%;
  transform: translateY(-50%);

  @include largerThan(medium) {
    max-width: 640px;
    padding: 100px;
  }
}
