.container {
  cursor: pointer;
}

.hoverEffect {
  transition: filter 0.5s;
}

.hoverEffect:hover {
  filter: brightness(96%);
}

.hoverEffect:active {
  filter: brightness(89%);
}
