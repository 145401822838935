@import '../../styles/breakpoints.scss';

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--headerHeight);
  background: white;
  padding: 0 16px;

  @include smallerThan(small) {
    padding: 0 16px;
  }

  .logoContainer {
    width: 130px;

    svg {
      @include largerThan(small) {
        width: 130px;
        height: auto;
      }
    }

    @include largerThan(small) {
      margin-top: 0;
    }
  }

  .leftContainer {
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    li {
      list-style: none;
      .navHeaderContainer {
        &.campaign {
          color: var(--darkRed);
          font-style: normal;
          .navHeader {
            &::after {
              border-color: var(--darkRed) transparent transparent transparent !important;
            }
          }
        }
      }
    }

    .mobileNav {
      position: absolute;
      margin-top: -1px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      left: 0;
      right: 0;
      top: var(--headerHeight);
      background: white;
      height: 0;
      transition: ease-in-out 0.2s;

      li + li {
        margin-top: 14px;
        padding-bottom: 14px;
      }

      li {
        font-weight: 300;
        border-bottom: 1px solid #eeeeee;
        padding: 0 var(--containerPadding) 14px var(--containerPadding);
        cursor: pointer;

        .navHeaderContainer {
          display: flex;
          justify-content: space-between;

          svg {
            height: 32px;
            width: 32px;
            margin-top: -8px;
          }
          a:focus {
            outline: none;
          }
        }

        .navHeader {
          width: 100%;
          margin-bottom: 0;
        }

        .navHeader::selection {
          background: #eeeeee;
          color: #000;
        }

        .navLink {
          width: 100%;
        }

        .navLink::selection {
          background: #eeeeee;
          color: #000;
        }

        ul {
          max-height: 0;
          transition: ease-in-out 0.25s;
          overflow: hidden;
          margin: 0;
          margin-left: 12px;

          li {
            border-bottom: none;
            font-weight: normal;
            padding: 0;

            &:first-child {
              margin-top: 16px;
            }

            &:last-child {
              margin-bottom: 16px;
            }

            a:focus {
              outline: none;
            }
          }
        }

        &.mobileLink {
          background: red;
        }

        &.expandable.open {
          ul {
            max-height: 500px;
          }
        }
      }

      &.open {
        height: calc(100vh - var(--headerHeight));
      }

      li.listItemForLogin {
        border-bottom: 0;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;

        button {
          height: 36px;
          width: 172px;
          min-width: auto;
        }
      }
    }

    .desktopNav {
      align-items: center;
      display: flex;

      .navLinkContainer {
        padding: 4px 16px 4px 28px;

        .navLink {
          &:focus {
            outline: none;
          }
          &:hover {
            font-weight: 600;
          }
        }
      }

      li {
        position: relative;
        cursor: pointer;
        padding: 4px 0 4px 0;

        .navHeaderContainer {
          position: relative;
          height: 30px;
          display: flex;
          align-items: center;

          a {
            vertical-align: sub;

            &:focus {
              outline: none;
            }
          }

          .navHeader {
            display: inline-block;
            padding: 0 min(6px, 0.8vw) 0 min(6px, 0.8vw);
            text-align: center;

            &::after {
              // @extend %splashBackground;
              opacity: 0;
              transition: opacity 0.3s;
            }

            &.active {
              font-weight: 600;

              &::after {
                opacity: 1;
              }
            }

            &::before {
              display: block;
              content: attr(title);
              font-weight: 600;
              height: 0;
              overflow: hidden;
              visibility: hidden;
              text-align: center;
            }
          }
        }

        ul {
          position: absolute;
          padding: 26px 0px 16px 0px;
          left: 0;
          top: 38px;
          z-index: 1;
          background-color: white;
          width: 300px;

          opacity: 0;
          visibility: hidden;
          transition: all 0.3s cubic-bezier(0.335, 0.01, 0.03, 1.36);

          li {
            margin: 0;

            a:focus {
              outline: none;
            }
          }

          li + li {
            margin-top: 10px;
          }
        }

        &:hover {
          .navHeaderContainer {
            .navHeader {
              font-weight: 500;
            }
          }
        }

        &.expandable {
          .expanded {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    @include largerThan(small) {
      grid-gap: 10px;
    }
  }
  .rightContainer {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 8px;

    .textButton {
      padding: 0;
      gap: 0.5rem;
      outline: none;
      background-color: transparent;
      transition: all 0.3s;

      div:first-child {
        height: auto;
        background-color: var(--kantarell-kantarell-2);
        padding: 5px;
        border-radius: 8px;
      }
    }

    .button {
      min-width: unset;
      max-height: 36px;
      padding-left: 18px;
      padding-right: 18px;
      font-size: var(--xtraSmallText);

      @include smallerThan(small) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .row {
      display: flex;
      gap: 8px;
    }
  }

  @include largerThan(large) {
    width: initial;
    font-size: var(--xtraSmallText);
  }

  &::before {
    content: '';
    position: fixed;
    top: var(--headerHeight);
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    z-index: -2;
  }

  &.overlay::before {
    pointer-events: all;
    opacity: 1;
  }
}

// %splashBackground {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   margin-left: auto;
//   margin-right: auto;
//   z-index: -1;
//   width: 82px;
//   background-image: url('/icons/illustrations-kludd-13.svg');
//   background-position: center;
//   background-repeat: no-repeat;
// }
